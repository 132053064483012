import "core-js/modules/es6.array.map.js";
import { useMutation } from '@vue/apollo-composable';
import UPDATE_SELECTED_OPTIONS from '~/graphql/mutations/questionnaire/UpdateSelectedOptions.gql';
export default defineComponent({
  inheritAttrs: false,
  props: {
    userInput: {
      type: Object,
      default: function _default() {}
    },
    isEditable: {
      type: Boolean,
      default: false
    },
    isMultiple: {
      type: Boolean,
      default: false
    },
    options: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  setup: function setup() {
    var _useMutation = useMutation(UPDATE_SELECTED_OPTIONS),
      updateSelectedOptions = _useMutation.mutate;
    return {
      updateSelectedOptions: updateSelectedOptions
    };
  },
  computed: {
    value: function value() {
      var values = this.$props.userInput.selectedOptions.map(function (e) {
        return e.id;
      });
      return this.isMultiple ? values : values[0];
    }
  },
  methods: {
    valueChange: function valueChange(value) {
      var values = this.isMultiple ? value : [value];
      this.updateSelectedOptions({
        userInputId: this.$props.userInput.id,
        values: values
      });
    }
  }
});