import { render, staticRenderFns } from "./SubquestionSmallOptions.vue?vue&type=template&id=76be0527&scoped=true"
import script from "./SubquestionSmallOptions.vue?vue&type=script&lang=ts"
export * from "./SubquestionSmallOptions.vue?vue&type=script&lang=ts"
import style0 from "./SubquestionSmallOptions.vue?vue&type=style&index=0&id=76be0527&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "76be0527",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IxSurveyQuestion: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/survey/Question.vue').default})


/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VChip } from 'vuetify/lib/components/VChip';
import { VChipGroup } from 'vuetify/lib/components/VChipGroup';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VInput } from 'vuetify/lib/components/VInput';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VChip,VChipGroup,VCol,VInput,VRow})
